import { objEntries } from '@lasso/shared/utils'
import { KeyOf } from '@lasso/shared/types'

import { SelectOptionType } from './types'

export const buildSelectOptions = <T extends Record<string, unknown>, V extends keyof T>(
  options: T[],
  valueKey: V,
  labelKey: keyof T,
): SelectOptionType<T[V] extends number | undefined | null ? number : string>[] => {
  return options
    .filter(option => option[valueKey] !== undefined && option[valueKey] !== null)
    .map((option) => {
      const value = option[valueKey]!

      return {
        value: (typeof value === 'number' ? value : String(value)) as T[V] extends number | undefined | null ? number : string,
        label: String(option[labelKey] ?? ''),
      }
    })
}

type BuildSelectOptionsFromRecord = {
  <R extends Record<string | number, T>, T extends string>(
    record: R,
    castIdToNumber: true,
  ): SelectOptionType<KeyOf<R, number> extends never ? number : KeyOf<R, number>>[]

  <R extends Record<string | number, T>, T extends string>(
    record: R,
  ): SelectOptionType<KeyOf<R, string>>[]

  <R extends Record<string | number, T>, T extends Record<string, unknown>>(
    record: R,
    labelKey: keyof T,
    castIdToNumber: true,
  ): SelectOptionType<KeyOf<R, number> extends never ? number : KeyOf<R, number>>[]

  <R extends Record<string | number, T>, T extends Record<string, unknown>>(
    record: R,
    labelKey: keyof T,
  ): SelectOptionType<KeyOf<R, string>>[]
}

/**
 * @example
 * const record = { key: { label: 'value' } }
 * // [{ value: 'key', label: 'value' }]
 * buildSelectOptionsFromRecord(record, 'label')
 *
 * @example
 * const record = { 1: { label: 'value' } }
 * // [{ value: 1, label: 'value' }]
 * buildSelectOptionsFromRecord(record, 'label', true)
 *
 * @example
 * const record = { key: 'value' }
 * // [{ value: 'key', label: 'value' }]
 * buildSelectOptionsFromRecord(record)
 *
 * @example
 * const record = { 1: 'value' }
 * // [{ value: 1, label: 'value' }]
 * buildSelectOptionsFromRecord(record, true)
 */
export const buildSelectOptionsFromRecord: BuildSelectOptionsFromRecord = <
  R extends Record<string | number, T>,
  T extends Record<string, unknown> | string,
>(
    record: R,
    ...args: [
    labelKey?: keyof T,
    castIdToNumber?: boolean,
    ] | [
    castIdToNumber?: boolean,
    ]
  ): SelectOptionType<any>[] => {
  const labelKey = typeof args[0] === 'boolean' ? undefined : args[0]
  const castIdToNumber = typeof args[0] === 'boolean' ? args[0] : args[1]

  return objEntries(record).map(([value, option]): SelectOptionType<any> => {
    const resultOption: SelectOptionType<any> = {
      value: castIdToNumber ? Number(value) : value,
      label: typeof option === 'object' ? String(option[labelKey!]) : option,
    }

    if (typeof option === 'object' && 'disabled' in option && typeof option.disabled === 'boolean') {
      resultOption.disabled = option.disabled
    }

    return resultOption
  })
}
